<script setup>
import AppTabs from '@/components/AppTabs.vue'
import AppIconBlock from '@/components/layout/AppIconBlock.vue'

const tabs = [
  {
    name: 'tab1',
    image: require('@/assets/module3/part3/step6tab1.svg')
  },
  {
    name: 'tab2',
    image: require('@/assets/module3/part3/step6tab2.svg')
  }
]
</script>

<template>
  <p class="paragraphe" v-html="$t('module3.part3.lesson1.step6.content')"></p>
  <br>
  <p class="paragraphe" v-html="$t('module3.part3.lesson1.step6.content2')"></p>
  <div class="step-tabs-wrapper">
    <app-tabs :tabs="tabs" class="image-tabs-wrapper">
      <template v-slot:tab="{ name, image, isCurrent }">
        <div class="image-tab" :class="{ current: isCurrent }">
          <img :src="image" :alt="name">
        </div>
      </template>
      <template v-slot:content="{ name }">
        <div v-if="name === 'tab1'" class="tab-content image-tab-content">
          <p class="tab-title">{{ $t('module3.part3.lesson1.step6.tab1.title') }}</p>
          <p class="paragraphe" v-html="$t('module3.part3.lesson1.step6.tab1.content')"></p>
          <app-icon-block :content="$t('module3.part3.lesson1.step6.tab1.info')"></app-icon-block>
        </div>
        <div v-else class="tab-content image-tab-content">
          <p class="tab-title">{{ $t('module3.part3.lesson1.step6.tab2.title') }}</p>
          <p class="paragraphe" v-html="$t('module3.part3.lesson1.step6.tab2.content')"></p>
        </div>
      </template>
    </app-tabs>
  </div>
</template>

<style scoped lang="scss">
@import '../../../../../styles/module3Lesson';
</style>
